#wy-anchor-content {
  .md-container>div:last-child {
    height: 100%;
  }

  .ant-anchor-wrapper {
    width: 290px;
    position: sticky;
    top: 70px;
    overflow: auto;


    .ant-anchor-ink-ball {
      border-radius: none !important;
      width: 2px !important;
      border: none;
      background-color: var(--primary-color) !important;
      height: 15px !important;
    }

    .ant-anchor-link>.ant-anchor-link>.ant-anchor-link>.ant-anchor-link-title {
      font-size: 0.75rem;
    }

    .ant-anchor-link {
      padding: 4px 0 4px 5px;
    }
  }
}

.reactMarkdown {
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-x: hidden;
  flex: 1;

  >ul {
    list-style-type: disc;
    ul {
      list-style-type: circle;
    }
  }

  ol {
    list-style-type: decimal;
  }

  /******
  >ul:last-child {
    position: fixed;
    width: 230px;
    top: 80px;
    right: 0;
    z-index: 99999;
    font-size: 14px;
    color: #333;
    list-style: none !important;
  }

  >ul:last-child ul {
    list-style: none !important;
  }

  >ul:last-child a {
    text-decoration: none !important;
    color: #666666 !important;
  }

  >ul:last-child a:hover {
    color: #6ca100 !important;
  }

  >ul:last-child>li>ul {
    padding-left: 0;
    font-size: 14px;
  }

  >ul:last-child>li>ul>li {
    margin-top: 16px;
  }

  >ul:last-child>li>ul>li>a {
    color: #333 !important;
  }

  >ul:last-child>li>ul>li>a:hover {
    color: #6ca100 !important;
  }

  >ul:last-child>li>ul>li>a:active {
    color: blue !important
  }

  >ul:last-child>li>ul>li>ul {
    margin-top: 5px;
    padding-left: 0;
    font-size: 12px;
  }

  >ul:last-child>li>ul>li>ul>li {
    margin-bottom: 5px;

  }
*****/


  * {
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    box-sizing: border-box
  }

  code {
    border-radius: 2px;
    color: #e60050;
    font-size: 12.8px;
    font-size: .8rem;
    margin: 0 2px;
    padding: 3px 5px;
    white-space: pre-wrap
  }

  code,
  pre {
    background-color: #f8f8f8;
    font-family: Roboto Mono, Monaco, courier, monospace
  }

  pre {
    -moz-osx-font-smoothing: initial;
    -webkit-font-smoothing: initial;
    line-height: 24px;
    line-height: 1.5rem;
    margin: 1.2em 0;
    overflow: auto;
    padding: 0 22.4px;
    padding: 0 1.4rem;
    position: relative;
    word-wrap: normal
  }

  .token.cdata,
  .token.comment,
  .token.doctype,
  .token.prolog {
    color: #8e908c
  }

  .token.namespace {
    opacity: .7
  }

  .token.boolean,
  .token.number {
    color: #c76b29
  }

  .token.punctuation {
    color: #525252
  }

  .token.property {
    color: #c08b30
  }

  .token.tag {
    color: #2973b7
  }

  .token.string {
    color: #6ca100;
    color: var(--theme-color, #6ca100)
  }

  .token.selector {
    color: #6679cc
  }

  .token.attr-name {
    color: #2973b7
  }

  .language-css .token.string,
  .style .token.string,
  .token.entity,
  .token.url {
    color: #22a2c9
  }

  .token.attr-value,
  .token.control,
  .token.directive,
  .token.unit {
    color: #6ca100;
    color: var(--theme-color, #6ca100)
  }

  .token.keyword {
    color: #e96900
  }

  .token.atrule,
  .token.regex,
  .token.statement {
    color: #22a2c9
  }

  .token.placeholder,
  .token.variable {
    color: #3d8fd1
  }

  .token.deleted {
    text-decoration: line-through
  }

  .token.inserted {
    border-bottom: 1px dotted #202746;
    text-decoration: none
  }

  .token.italic {
    font-style: italic
  }

  .token.bold,
  .token.important {
    font-weight: 700
  }

  .token.important {
    color: #c94922
  }

  .token.entity {
    cursor: help
  }

  pre>code,
  pre[class*="language"] {
    -moz-osx-font-smoothing: initial;
    -webkit-font-smoothing: initial;
    background-color: #f8f8f8;
    border-radius: 2px;
    color: #525252;
    display: block;
    font-family: Roboto Mono, Monaco, courier, monospace;
    font-size: 12.8px;
    font-size: .8rem;
    line-height: 2em;
    max-width: inherit;
    overflow: inherit;
    padding: .8em 1.4em;
    white-space: pre
  }

  pre>code {
    padding-left: 0;
    padding-right: 0
  }

  code:after,
  code:before {
    letter-spacing: .8px;
    letter-spacing: .05rem
  }

  code .token {
    -moz-osx-font-smoothing: initial;
    -webkit-font-smoothing: initial;
    min-height: 24px;
    min-height: 1.5rem
  }

  pre:after {
    color: #ccc;
    content: attr(data-lang);
    font-size: 9.6px;
    font-size: .6rem;
    font-weight: 600;
    height: 15px;
    line-height: 15px;
    padding: 5px 10px 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0
  }

  pre[data-line] {
    position: relative;
    padding: 1em 0 1em 3em !important
  }

  pre[data-line] .line-highlight-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    display: block;
    width: 100%
  }

  pre[data-line] .line-highlight {
    position: absolute;
    left: 0;
    right: 0;
    padding: inherit 0;
    margin-top: 1em;
    background: rgba(153, 122, 102, 0.08);
    background: linear-gradient(to right, rgba(153, 122, 102, 0.1) 70%, rgba(153, 122, 102, 0));
    pointer-events: none;
    line-height: inherit;
    white-space: pre
  }

  pre[data-line] .line-highlight:before,
  pre[data-line] .line-highlight[data-end]:after {
    content: attr(data-start);
    position: absolute;
    top: .4em;
    left: .6em;
    min-width: 1em;
    padding: 0 .5em;
    background-color: rgba(153, 122, 102, 0.4);
    color: #f5f2f0;
    font: bold 65%/1.5 sans-serif;
    text-align: center;
    vertical-align: .3em;
    border-radius: 999px;
    text-shadow: none;
    box-shadow: 0 1px white
  }

  pre[data-line] .line-highlight[data-end]:after {
    content: attr(data-end);
    top: auto;
    bottom: .4em
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Mono'), local('RobotoMono-Regular'), url(https://fonts.gstatic.com/s/robotomono/v7/L0x5DF4xlVMF-BfR8bXMIjhLq3o.ttf) format('truetype')
  }

  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdr.ttf) format('truetype')
  }

  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7g.ttf) format('truetype')
  }

  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdr.ttf) format('truetype')
  }

  * {
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    box-sizing: border-box
  }

  body:not(.ready) {
    overflow: hidden
  }

  body:not(.ready) .app-nav,
  body:not(.ready)>nav,
  body:not(.ready) [data-cloak] {
    display: none
  }

  div#app {
    font-size: 30px;
    font-weight: lighter;
    margin: 40vh auto;
    text-align: center
  }

  div#app:empty:before {
    content: "Loading..."
  }

  .emoji {
    height: 19.2px;
    height: 1.2rem;
    vertical-align: middle
  }

  .progress {
    background-color: #6ca100;
    background-color: var(--theme-color, #6ca100);
    height: 2px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: width .2s, opacity .4s;
    width: 0;
    z-index: 5
  }

  .search .search-keyword,
  .search a:hover {
    color: #6ca100;
    color: var(--theme-color, #6ca100)
  }

  .search .search-keyword {
    font-style: normal;
    font-weight: 700
  }

  body,
  html {
    height: 100%
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #34495e;
    font-family: Source Sans Pro, Helvetica Neue, Arial, sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    margin: 0;
    overflow-x: hidden
  }

  img {
    max-width: 100%
  }

  a[disabled] {
    cursor: not-allowed;
    opacity: .6
  }

  kbd {
    color: #34495e;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: inline-block;
    font-size: 12px !important;
    line-height: 12px;
    margin-bottom: 3px;
    padding: 3px 5px;
    vertical-align: middle
  }

  .task-list-item {
    list-style-type: none
  }

  li input[type=checkbox] {
    margin: 0 .2em .25em -1.6em;
    vertical-align: middle
  }

  .app-nav {
    left: 0;
    margin: 25px 60px 0 0;
    position: absolute;
    right: 0;
    text-align: right;
    z-index: 2
  }

  .app-nav p {
    margin: 0
  }

  .app-nav>a {
    margin: 0 16px;
    margin: 0 1rem;
    padding: 5px 0
  }

  .app-nav li,
  .app-nav ul {
    display: inline-block;
    list-style: none;
    margin: 0
  }

  .app-nav a {
    color: inherit;
    font-size: 16px;
    text-decoration: none;
    transition: color .3s
  }

  .app-nav a.active,
  .app-nav a:hover {
    color: #6ca100;
    color: var(--theme-color, #6ca100)
  }

  .app-nav a.active {
    border-bottom: 2px solid #6ca100;
    border-bottom: 2px solid var(--theme-color, #6ca100)
  }

  .app-nav li {
    display: inline-block;
    margin: 0 16px;
    margin: 0 1rem;
    padding: 5px 0;
    position: relative
  }

  .app-nav li ul {
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    display: none;
    max-height: calc(100vh - 61px);
    overflow-y: scroll;
    padding: 10px 0;
    position: absolute;
    right: -15px;
    text-align: left;
    top: 100%;
    white-space: nowrap
  }

  .app-nav li ul li {
    display: block;
    font-size: 14px;
    line-height: 16px;
    line-height: 1rem;
    margin: 0;
    margin: 8px 14px;
    white-space: nowrap
  }

  .app-nav li ul a {
    display: block;
    font-size: inherit;
    margin: 0;
    padding: 0
  }

  .app-nav li ul a.active {
    border-bottom: 0
  }

  .app-nav li:hover ul {
    display: block
  }

  .app-nav.no-badge {
    margin-right: 25px
  }

  .github-corner {
    border-bottom: 0;
    position: fixed;
    right: 0;
    text-decoration: none;
    top: 0;
    z-index: 1
  }

  .github-corner svg {
    color: #fff;
    fill: #6ca100;
    fill: var(--theme-color, #6ca100);
    height: 80px;
    width: 80px
  }

  .github-corner:hover .octo-arm {
    -webkit-animation: a .56s ease-in-out;
    animation: a .56s ease-in-out
  }

  main {
    display: block;
    position: relative;
    width: 100vw;
    height: 100%;
    z-index: 0
  }

  .anchor {
    display: inline-block;
    text-decoration: none;
    transition: all .3s
  }

  .anchor span {
    color: #34495e
  }

  .anchor:hover {
    text-decoration: underline
  }

  .sidebar {
    border-right: 1px solid rgba(0, 0, 0, 0.07);
    overflow-y: auto;
    padding: 40px 0 0;
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    transition: -webkit-transform .25s ease-out;
    transition: transform .25s ease-out;
    transition: transform .25s ease-out, -webkit-transform .25s ease-out;
    width: 300px;
    z-index: 3
  }

  .sidebar>h1 {
    margin: 0 auto 16px;
    margin: 0 auto 1rem;
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center
  }

  .sidebar>h1 a {
    color: inherit;
    text-decoration: none
  }

  .sidebar>h1 .app-nav {
    display: block;
    position: static
  }

  .sidebar .sidebar-nav {
    line-height: 2em;
    padding-bottom: 40px
  }

  .sidebar ul {
    margin: 0;
    padding: 0
  }

  .sidebar li>p {
    font-weight: 700;
    margin: 0
  }

  .sidebar ul,
  .sidebar ul li {
    list-style: none
  }

  .sidebar ul li a {
    border-bottom: none;
    display: block
  }

  .sidebar ul li ul {
    padding-left: 20px
  }

  .sidebar::-webkit-scrollbar {
    width: 4px
  }

  .sidebar::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px
  }

  .sidebar:hover::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 53%, 0.4)
  }

  .sidebar:hover::-webkit-scrollbar-track {
    background: hsla(0, 0%, 53%, 0.1)
  }

  .sidebar-toggle {
    background-color: transparent;
    background-color: hsla(0, 0%, 100%, 0.8);
    border: 0;
    outline: none;
    padding: 10px;
    bottom: 0;
    left: 0;
    position: absolute;
    text-align: center;
    transition: opacity .3s;
    width: 30px;
    width: 284px;
    z-index: 4
  }

  .sidebar-toggle .sidebar-toggle-button:hover {
    opacity: .4
  }

  .sidebar-toggle span {
    background-color: #6ca100;
    background-color: var(--theme-color, #6ca100);
    display: block;
    margin-bottom: 4px;
    width: 16px;
    height: 2px
  }

  body.sticky .sidebar,
  body.sticky .sidebar-toggle {
    position: fixed
  }

  .content {
    padding-top: 60px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 300px;
    position: absolute;
    transition: left .25s ease
  }

  >* {
    box-sizing: border-box;
    font-size: inherit
  }

  >:first-child {
    margin-top: 0 !important
  }

  hr {
    border: none;
    border-bottom: 1px solid #eee;
    margin: 2em 0
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    margin-bottom: 16px;
    margin-bottom: 1rem;
    overflow: auto;
    width: 100%
  }

  th {
    font-weight: 700
  }

  td,
  th {
    border: 1px solid #ddd;
    padding: 6px 13px
  }

  tr {
    border-top: 1px solid #ccc
  }

  p.tip,
  tr:nth-child(2n) {
    background-color: #f8f8f8
  }

  p.tip {
    border-bottom-right-radius: 2px;
    border-left: 4px solid #f66;
    border-top-right-radius: 2px;
    margin: 2em 0;
    padding: 12px 24px 12px 30px;
    position: relative
  }

  p.tip code {
    background-color: #efefef
  }

  p.tip em {
    color: #34495e
  }

  p.tip:before {
    background-color: #f66;
    border-radius: 100%;
    color: #fff;
    content: "!";
    font-family: Dosis, Source Sans Pro, Helvetica Neue, Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
    left: -12px;
    line-height: 20px;
    position: absolute;
    width: 20px;
    height: 20px;
    text-align: center;
    top: 14px
  }

  p.warn {
    background: rgba(66, 185, 131, 0.1);
    border-radius: 2px;
    padding: 16px;
    padding: 1rem
  }

  body.close .sidebar {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px)
  }

  body.close .sidebar-toggle {
    width: auto
  }

  body.close .content {
    left: 0
  }

  @media print {

    .app-nav,
    .github-corner,
    .sidebar,
    .sidebar-toggle {
      display: none
    }
  }

  @media screen and (max-width:768px) {

    .github-corner,
    .sidebar,
    .sidebar-toggle {
      position: fixed
    }

    .app-nav {
      margin-top: 16px
    }

    .app-nav li ul {
      top: 30px
    }

    main {
      height: auto;
      overflow-x: hidden
    }

    .sidebar {
      left: -300px;
      transition: -webkit-transform .25s ease-out;
      transition: transform .25s ease-out;
      transition: transform .25s ease-out, -webkit-transform .25s ease-out
    }

    .content {
      left: 0;
      max-width: 100vw;
      position: static;
      padding-top: 20px;
      transition: -webkit-transform .25s ease;
      transition: transform .25s ease;
      transition: transform .25s ease, -webkit-transform .25s ease
    }

    .app-nav,
    .github-corner {
      transition: -webkit-transform .25s ease-out;
      transition: transform .25s ease-out;
      transition: transform .25s ease-out, -webkit-transform .25s ease-out
    }

    .sidebar-toggle {
      background-color: transparent;
      width: auto;
      padding: 30px 30px 10px 10px
    }

    body.close .sidebar {
      -webkit-transform: translateX(300px);
      transform: translateX(300px)
    }

    body.close .sidebar-toggle {
      background-color: hsla(0, 0%, 100%, 0.8);
      transition: background-color 1s;
      width: 284px;
      padding: 10px
    }

    body.close .content {
      -webkit-transform: translateX(300px);
      transform: translateX(300px)
    }

    body.close .app-nav,
    body.close .github-corner {
      display: none
    }

    .github-corner .octo-arm {
      -webkit-animation: a .56s ease-in-out;
      animation: a .56s ease-in-out
    }

    .github-corner:hover .octo-arm {
      -webkit-animation: none;
      animation: none
    }
  }

  @-webkit-keyframes a {

    0%,
    to {
      -webkit-transform: rotate(0);
      transform: rotate(0)
    }

    20%,
    60% {
      -webkit-transform: rotate(-25deg);
      transform: rotate(-25deg)
    }

    40%,
    80% {
      -webkit-transform: rotate(10deg);
      transform: rotate(10deg)
    }
  }

  @keyframes a {

    0%,
    to {
      -webkit-transform: rotate(0);
      transform: rotate(0)
    }

    20%,
    60% {
      -webkit-transform: rotate(-25deg);
      transform: rotate(-25deg)
    }

    40%,
    80% {
      -webkit-transform: rotate(10deg);
      transform: rotate(10deg)
    }
  }

  section.cover {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: none
  }

  section.cover .cover-main {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: -20px 16px 0;
    text-align: center;
    z-index: 1
  }

  section.cover a {
    color: inherit
  }

  section.cover a,
  section.cover a:hover {
    text-decoration: none
  }

  section.cover p {
    line-height: 24px;
    line-height: 1.5rem;
    margin: 1em 0
  }

  section.cover h1 {
    color: inherit;
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 300;
    margin: 10px 0 40px;
    margin: .625rem 0 2.5rem;
    position: relative;
    text-align: center
  }

  section.cover h1 a {
    display: block
  }

  section.cover h1 small {
    bottom: -7px;
    bottom: -0.4375rem;
    font-size: 16px;
    font-size: 1rem;
    position: absolute
  }

  section.cover blockquote {
    font-size: 24px;
    font-size: 1.5rem;
    text-align: center
  }

  section.cover ul {
    line-height: 1.8;
    list-style-type: none;
    margin: 1em auto;
    max-width: 500px;
    padding: 0
  }

  section.cover .cover-main>p:last-child a {
    border-color: #6ca100;
    border: 1px solid var(--theme-color, #6ca100);
    border-radius: 2rem;
    box-sizing: border-box;
    color: #6ca100;
    color: var(--theme-color, #6ca100);
    display: inline-block;
    font-size: 16.8px;
    font-size: 1.05rem;
    letter-spacing: 1.6px;
    letter-spacing: .1rem;
    margin-right: 16px;
    margin-right: 1rem;
    padding: .75em 32px;
    padding: .75em 2rem;
    text-decoration: none;
    transition: all .15s ease
  }

  section.cover .cover-main>p:last-child a:last-child {
    background-color: #6ca100;
    background-color: var(--theme-color, #6ca100);
    color: #fff;
    margin-right: 0
  }

  section.cover .cover-main>p:last-child a:last-child:hover {
    color: inherit;
    opacity: .8
  }

  section.cover .cover-main>p:last-child a:hover {
    color: inherit
  }

  section.cover blockquote>p>a {
    border-bottom: 2px solid #6ca100;
    border-bottom: 2px solid var(--theme-color, #6ca100);
    transition: color .3s
  }

  section.cover blockquote>p>a:hover {
    color: #6ca100;
    color: var(--theme-color, #6ca100)
  }

  section.cover.show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }

  section.cover.has-mask .mask {
    background-color: #fff;
    opacity: .8;
    position: absolute;
    width: 100%;
    height: 100%
  }

  .sidebar,
  body {
    background-color: #fff
  }

  .sidebar {
    color: #364149
  }

  .sidebar li {
    margin: 6px 0 6px 15px
  }

  .sidebar ul li a {
    color: #505d6b;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .sidebar ul li a:hover {
    text-decoration: underline
  }

  .sidebar ul li ul {
    padding: 0
  }

  .sidebar ul li.active>a {
    border-right: 2px solid;
    color: #6ca100;
    color: var(--theme-color, #6ca100);
    font-weight: 600
  }

  .app-sub-sidebar li:before {
    content: "-";
    padding-right: 4px;
    float: left
  }

  h1,
  h2,
  h3,
  h4,
  strong {
    color: #2c3e50;
    font-weight: 600
  }

  a {
    color: #6ca100;
    color: var(--theme-color, #6ca100);
    font-weight: 600
  }

  .md-sidebar-toc a {
    color: #6ca100;
    font-weight: 600
  }

  h1 {
    font-size: 32px;
    font-size: 2rem;
    margin: 0 0 16px;
    margin: 0 0 1rem
  }

  h2 {
    font-size: 28px;
    font-size: 1.75rem;
    margin: 45px 0 12.8px;
    margin: 45px 0 .8rem
  }

  h3 {
    font-size: 24px;
    font-size: 1.5rem;
    margin: 40px 0 9.6px;
    margin: 40px 0 .6rem
  }

  h4 {
    font-size: 20px;
    font-size: 1.25rem
  }

  h5,
  h6 {
    font-size: 16px;
    font-size: 1rem
  }

  h6 {
    color: #777
  }

  figure,
  p {
    margin: 1.2em 0
  }

  ol,
  p,
  ul {
    line-height: 25.6px;
    line-height: 1.6rem;
    word-spacing: .8px;
    word-spacing: .05rem
  }

  ol,
  ul {
    padding-left: 24px;
    padding-left: 1.5rem
  }

  blockquote {
    border-left: 4px solid #6ca100;
    border-left: 4px solid var(--theme-color, #6ca100);
    color: #858585;
    background-color: #f0f0f0;
    margin: 2em 0;
    padding-left: 20px
  }

  blockquote p {
    font-weight: 600;
    margin-left: 0
  }

  iframe {
    margin: 1em 0
  }

  em {
    color: #7f8c8d
  }


  .pagebreak,
  .newpage {
    page-break-before: always
  }

  pre.line-numbers {
    position: relative;
    padding-left: 3.8em;
    counter-reset: linenumber
  }

  pre.line-numbers>code {
    position: relative
  }

  pre.line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 1em;
    font-size: 100%;
    left: 0;
    width: 3em;
    letter-spacing: -1px;
    border-right: 1px solid #999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }

  pre.line-numbers .line-numbers-rows>span {
    pointer-events: none;
    display: block;
    counter-increment: linenumber
  }

  pre.line-numbers .line-numbers-rows>span:before {
    content: counter(linenumber);
    color: #999;
    display: block;
    padding-right: .8em;
    text-align: right
  }

  .mathjax-exps .MathJax_Display {
    text-align: center !important
  }

  :not([for="preview"]) .code-chunk .btn-group {
    display: none
  }

  :not([for="preview"]) .code-chunk .status {
    display: none
  }

  :not([for="preview"]) .code-chunk .output-div {
    margin-bottom: 16px
  }

  .scrollbar-style::-webkit-scrollbar {
    width: 8px
  }

  .scrollbar-style::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent
  }

  .scrollbar-style::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(150, 150, 150, 0.66);
    border: 4px solid rgba(150, 150, 150, 0.66);
    background-clip: content-box
  }

  html body[for="html-export"]:not([data-presentation-mode]) {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    overflow: auto
  }

  html body[for="html-export"]:not([data-presentation-mode]) {
    position: relative;
    top: 0
  }

  @media screen and (min-width:914px) {
    html body[for="html-export"]:not([data-presentation-mode]) {
      padding: 2em calc(50% - 457px + 2em)
    }
  }

  @media screen and (max-width:914px) {
    html body[for="html-export"]:not([data-presentation-mode]) {
      padding: 2em
    }
  }

  @media screen and (max-width:450px) {
    html body[for="html-export"]:not([data-presentation-mode]) {
      font-size: 14px !important;
      padding: 1em
    }
  }

  @media print {
    html body[for="html-export"]:not([data-presentation-mode]) #sidebar-toc-btn {
      display: none
    }
  }

  html body[for="html-export"]:not([data-presentation-mode]) #sidebar-toc-btn {
    position: fixed;
    bottom: 8px;
    left: 8px;
    font-size: 28px;
    cursor: pointer;
    color: inherit;
    z-index: 99;
    width: 32px;
    text-align: center;
    opacity: .4
  }

  html body[for="html-export"]:not([data-presentation-mode])[html-show-sidebar-toc] #sidebar-toc-btn {
    opacity: 1
  }

  html body[for="html-export"]:not([data-presentation-mode])[html-show-sidebar-toc] .md-sidebar-toc {
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    padding: 32px 0 48px 0;
    font-size: 14px;
    box-shadow: 0 0 4px rgba(150, 150, 150, 0.33);
    box-sizing: border-box;
    overflow: auto;
    background-color: inherit
  }

  html body[for="html-export"]:not([data-presentation-mode])[html-show-sidebar-toc] .md-sidebar-toc::-webkit-scrollbar {
    width: 8px
  }

  html body[for="html-export"]:not([data-presentation-mode])[html-show-sidebar-toc] .md-sidebar-toc::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent
  }

  html body[for="html-export"]:not([data-presentation-mode])[html-show-sidebar-toc] .md-sidebar-toc::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(150, 150, 150, 0.66);
    border: 4px solid rgba(150, 150, 150, 0.66);
    background-clip: content-box
  }

  html body[for="html-export"]:not([data-presentation-mode])[html-show-sidebar-toc] .md-sidebar-toc a {
    text-decoration: none
  }

  html body[for="html-export"]:not([data-presentation-mode])[html-show-sidebar-toc] .md-sidebar-toc ul {
    padding: 0 1.6em;
    margin-top: .8em
  }

  html body[for="html-export"]:not([data-presentation-mode])[html-show-sidebar-toc] .md-sidebar-toc li {
    margin-bottom: .8em
  }

  html body[for="html-export"]:not([data-presentation-mode])[html-show-sidebar-toc] .md-sidebar-toc ul {
    list-style-type: none
  }

  html body[for="html-export"]:not([data-presentation-mode])[html-show-sidebar-toc] {
    left: 300px;
    width: calc(100% - 300px);
    padding: 2em calc(50% - 457px - 150px);
    margin: 0;
    box-sizing: border-box
  }

  @media screen and (max-width:1274px) {
    html body[for="html-export"]:not([data-presentation-mode])[html-show-sidebar-toc] {
      padding: 2em
    }
  }

  @media screen and (max-width:450px) {
    html body[for="html-export"]:not([data-presentation-mode])[html-show-sidebar-toc] {
      width: 100%
    }
  }

  html body[for="html-export"]:not([data-presentation-mode]):not([html-show-sidebar-toc]) {
    left: 50%;
    transform: translateX(-50%)
  }

  html body[for="html-export"]:not([data-presentation-mode]):not([html-show-sidebar-toc]) .md-sidebar-toc {
    display: none
  }
}
@primary-color: #6CA100;