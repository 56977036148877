.ApplicationsDetails {
  .title {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }
}

@primary-color: #6CA100;