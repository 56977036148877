@import '~antd/dist/antd.less';
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
:root {
  --primary-color: @primary-color;
  --head-color: #fff;
  --site-background-color: var(--primary-background-color);
  --site-background-dark-color: @primary-7;
  --input-hover-border-color: @input-hover-border-color;
  --border-radius-base: @border-radius-base;
  --primary-border-color: #d7d7d7;
  --primary-background-color: #f6f6f6;
  --primary-font-color: #444444;
  --primary-label-color: #666666;
  --primary-box-shadow:0 5px 15px rgba(0, 0, 0, 0.11);
  --primary-border-radius: 10px;
  --status-green-color: #008900;
  --status-red-color: #c03344;
  --status-yellow-color: #ee8b00;
  --status-gray-color: #d7d7d7;
  --status-black-color: #333333;

}
#root{
  height: 100%;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@primary-color: #6CA100;