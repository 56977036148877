.Applications {
  .title {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
  }
  .CredentialsBtn {
    border-radius: 4px;
    background-color: var(--primary-color) !important;
    border: 0 !important;
  }
  .CredentialsIcon {
    width: 20px;
    height: 20px;
    background: url('../../assets//image/yaoshi.png') no-repeat center;
    background-size: 100%;
  }
}


.ant-table-cell::before{
background-color: #ffffff !important;
}
@primary-color: #6CA100;